import * as React from "react";
import MarqueBarBlog from "./MarqueBarBlog";
import FeatureBarPink from "./FeatureBarPink";
import Footer from "./Footer";
import NavBar from "./Nav/NavBar";
import NewsletterBar from "./NewsletterBar";
import CookieBanner from "./CookieBanner";

const LayoutBlog = ({ children }) => {
  return (
    <div>
      <CookieBanner />
      <NavBar />
      <FeatureBarPink />
      <MarqueBarBlog />

      {children}

      <div style={{ marginTop: "30vw" }}>
        <NewsletterBar />
      </div>
      
      <Footer />
    </div>
  );
};

export default LayoutBlog;
