import React, { useEffect, useRef, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import "../scss/FirstPressRelease.scss";
import email from "./email_black.png"; 
import download from "./download.png"; 
import leena from "./leena press.png"; 
import luxgroups from "./Luxury Groups 2023 1.png"; 
import luxgroups2 from "./Luxury Women CEOs 2.png"; 
import { Helmet } from "react-helmet";

export default function FirstPressRelease() {
  const data = useStaticQuery(graphql`
  query MyQuery10500 {
    allContentfulHeroImage (filter:{id:{eq:"883e2d6d-ddc6-5719-94f6-a0e0f4840903"}}){
      edges {
        node {
          id
          socialMediaIcons {
            linkedInIcon {
              image {
                file {
                  url
                }
              }
            }
            facebookIcon {
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`);

const [isVisible, setIsVisible] = useState(true);

useEffect(() => {
  Aos.init({ duration: 2000 });

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const breakpoint = 1000; // Adjust this value to determine when the icons should disappear

    if (scrollPosition > breakpoint) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  window.addEventListener("scroll", handleScroll);

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);

const options = {
  renderText: (text) => {
    // Split the text based on the word you want (e.g., "breakpoint-word")
    const breakpointWord = "calledp";
    const splitText = text.split(breakpointWord);

    // Create an array of React components
    const reactComponents = splitText.flatMap((text, index) => [
      // Add a <br> tag after the word in all elements except the last one
      <React.Fragment key={`text-${index}`}>{text}</React.Fragment>,
      index !== splitText.length - 1 && <br key={`br-${index}`} />,
    ]);

    return <React.Fragment>{reactComponents}</React.Fragment>;
  },
};


const [isSocialMediaVisible, setIsSocialMediaVisible] = useState(false);

useEffect(() => {
  Aos.init({ duration: 2000 });

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const scrollThreshold = 600; // Adjust this value to determine when to show social icons

    if (scrollPosition > scrollThreshold) {
      setIsSocialMediaVisible(true);
    } else {
      setIsSocialMediaVisible(false);
    }
  };

  window.addEventListener("scroll", handleScroll);

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);

// /press-releases/luxury-diversity-and-inclusion

  return (
    <>
    <Helmet>
    <title>The Fresh Kid Releases First DE&I Luxury Leadership Report</title>
    <meta name="description" content="First Diversity and Inclusion in Luxury Leadership Report - Discover Diversity and Inclusion in Luxury Leadership" />
    <meta name="keywords" content="Luxury Diversity and Inclusion, Luxury Women in Leadership" />
    <h1>First Industry Insight Report into Diversity of Luxury’s Top CEOs</h1>
    <h2>Report Focuses on Women and People of Colour Luxury CEOs</h2>
  </Helmet>
    <div className="firstpresspage">
      <div className={"imageContainerFPP"}>
        <img
          className={"bigImage"}
          alt={""}
          key={""}
          src={leena}
        />
      </div>


      <div className="SocialMediaPRF">
          <div className="SharePRF">Share</div>
          <a href="https://www.linkedin.com/shareArticle?mini=true&url=thefreshkid.com/the-fresh-kid/">
          <img
              className="SocialIconPRF"
              alt=""
              key=""
              src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.linkedInIcon.image.file.url}
          />
          </a>

          <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthefreshkid.com/the-fresh-kid/" target="_blank">
          <img
              className="SocialIconPRF"
              alt=""
              key=""
              src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.facebookIcon.image.file.url}
          />
          </a>

          <a href="mailto:?subject=From thefreshkid.com: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry&body=Check out this article: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry www.thefreshkid.com/the-fresh-kid/" target="_blank">
          <img
              className="SocialIcon1PRF"
              alt=""
              key=""
              src={email}
              style={{ marginRight: '100px' }}
          />
          </a>
      </div>



      {/* <div className="SocialMediaBlogPRF" data-aos="fade-up">
      {isSocialMediaVisible && (
          <>
            <div
              className="SharePRF"
            >
              Share
            </div>
            <a href="https://www.linkedin.com/shareArticle?mini=true&url=thefreshkid.com/the-fresh-kid/">
              <img
                className="SocialIconBlogPRF"
                alt=""
                key=""
                src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.linkedInIcon.image.file.url}
              />
            </a>

            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthefreshkid.com/the-fresh-kid/" target="_blank">
            <img
                className="SocialIconBlogPRF"
                alt=""
                key=""
                src={data.allContentfulHeroImage.edges[0].node.socialMediaIcons.facebookIcon.image.file.url}
              />
            </a>

            <a href="mailto:?subject=From thefreshkid.com: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry&body=Check out this article: What is a Fresh Kid? How Street Culture made Luxury Fashion a Billion Dollar Industry www.thefreshkid.com/the-fresh-kid/" target="_blank">
              <img
                className="SocialIconBlog1PRF"
                alt=""
                key=""
                src={email}
                style={{ marginRight: '100px' }} 
              />
            </a>
          </>
        )}
      </div> */}



      <div className="pressReleaseContainer">
        <p className="pressReleaseTitle1">The Fresh Kid - Luxury Data Analyst Releases</p>
        <p className="pressReleaseSubTitle1">First Diversity and Inclusion in Luxury Leadership Report  </p>
        <p className="pressReleaseYear1">Thursday 25th January 2024</p>

        <div className="rectangleBoxOutside">
          <div className='mediaEnquirie'><strong>Media Enquiries</strong></div>
          <div className="rectangleBoxs">
              <div className='mediaEnquiriess'><strong>The Fresh Kid</strong></div>
              <div className='pressEmails'>press@thefreshkid.com</div>
          </div>
        </div>
        <div className="pressReleaseContent">
          <div className="pressReleaseBodyTitles">First Industry Insight Report into Diversity of Luxury’s Top CEOs</div>
          <div className="squareText">
            <p>
                The <a href="https://thefreshkid.com/luxury-diversity-and-inclusion/"> <strong>'Diversity and Inclusion in Luxury Leadership' report</strong></a> is an independent analysis of the age, gender, ethnicity, education, career path and socio-economic status of the world’s most valuable luxury brands CEOs.
            </p>

          </div>
          <div className="squareText">
            <p>
                Undertaken by London based luxury data engineer and analyst <a href="https://thefreshkid.com/about/"><strong>Yemi Lawal aka The Fresh Kid</strong></a>.
            </p>
          </div>
          <div className="squareText">
          <p>
            The analysis included CEOs of the brands of
            <a href="https://www.lvmh.com/"> <strong>LVMH,</strong> </a>
            <a href="https://www.kering.com/en/group/"><strong>Kering,</strong> </a>
            <a href="https://www.richemont.com/"><strong>Richemont,</strong> </a>
            <a href="https://www.chanel.com/gb/financial-results/"><strong>Chanel,</strong> </a>
            <a href="https://finance.hermes.com/"><strong>Hermes,</strong> </a>
            <a href="https://www.monclergroup.com/en/group"><strong>Moncler,</strong> </a>
            <a href="https://www.pradagroup.com/en.html"><strong>Prada Group</strong></a>, and
            <a href="https://www.capriholdings.com/corporate-overview/default.aspx"> <strong>Capri Holdings</strong></a>.
            These are the world's most valuable luxury companies.
        </p>
            
          </div>
        </div>
      </div>


      <div className={"imageContainerFPP1"}>
        <img
          className={"bigImage"}
          alt={""}
          key={""}
          src={luxgroups}
        />
      </div>

      <div className="pressReleaseContent2">
        <div className="pressReleaseBodyTitles">Report Focuses on Women and People of Colour Luxury CEOs</div>
        <div className="squareText">
          <p>
          The <strong>Diversity and Inclusion in Luxury Leadership’ report</strong>provides analysis on representation of women and people of colour ceos at luxury’s top brands and companies.
          </p>
        </div>
        <div className="squareText">
          <p>
          Providing insights into the real state of play of diversity and inclusion in luxury’s c-suite.
          </p>
        </div>
      </div>
        <div className={"imageContainerFPP2"}>
        <img
          className={"bigImage"}
          alt={""}
          key={""}
          src={luxgroups2}
        />
      </div>
      <div className="PressBottomTextContainer">
        <div className="PressBottomTextSub">
          <p>
          <strong>Media Contact:</strong>
          </p>
        </div>
        <div className="PressBottomTextSub">
          <p>
          Yemi Lawal, The Fresh Kid, <strong>press@thefreshkid.com</strong>, <strong>www.thefreshkid.com</strong>
          </p>
        </div>
      </div>

    </div>
    </>
  );
}

