import * as React from "react"
import LayoutBlog from "../../components/LayoutBlog"
import FirstPressRelease from "../../components/PressReleases/FirstPressRelease"
import PressComments from '../../components/PressReleases/PressComments'

import { Helmet } from "react-helmet";

function blog({ data }) {
  return (
    
    <LayoutBlog>
      <FirstPressRelease/>
      <PressComments/>
    </LayoutBlog>
  )
}

export default blog





